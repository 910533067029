<template>
  <div class="card-business">
    <div class="title-business">
      <b-card-title
        title-tag="h1"
        class="title-text"
      >
        {{ $t('profile.textGeneral') }}
      </b-card-title>
    </div>
    <div class="form-business">
      <b-row>
        <b-col>
          <validation-observer
            ref="formValidation"
          >
            <b-form>
              <b-row>
                <!-- email -->
                <b-col
                  sm="12"
                  md="12"
                  :lg="refCode && refMode ? '6' : '12'"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label class="form-label">
                        Email <span class="text-danger">*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="email"
                      name="email"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.email"
                          name="email"
                          class="input-height"
                          disabled
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- email -->

                <b-col
                  v-if="refCode && refMode"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label class="form-label">
                        {{ $t('common.labelReferral') }} <span class="text-danger" />
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('common.labelReferral')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="refCode"
                          name="referralCode"
                          class="input-height"
                          disabled
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="select-country">
                <!-- Full Name -->
                <b-col
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="fullName"
                      >
                        {{ $t('common.labelFullName') }} ( {{ $t('common.textOr') }} {{ $t('common.labelBusinessName') }} )<span
                          class="text-danger"
                        >*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="fullName"
                      :name="$t('common.labelFullName')"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.fullName"
                          name="full name"
                          class="input-height"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('common.placeholderFullName')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Full Name -->
                <!-- Business Model -->
                <b-col
                  v-if="!isVNWhiteLabel"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('profile.textBusinessModel')"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelBusinessModel') }} <span class="text-danger">*</span>
                          </label>
                        </div>
                        <v-select
                          v-model="userData.businessModel"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderBusinessModel')"
                          class="currency input-height"
                          :tabindex="2"
                          :clearable="false"
                          :options="optionsBusinessModel"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Business Model -->
                <!-- Business Type -->
                <b-col
                  v-if="!isVNWhiteLabel"
                  sm="12"
                  md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('stepGuideControl.stepVerifyInfo.labelBusinessType')"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelBusinessType') }} <span class="text-danger">*</span>
                          </label>
                        </div>
                        <v-select
                          v-model="userData.type"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.labelBusinessType')"
                          class="currency input-height"
                          :tabindex="2"
                          :clearable="false"
                          :options="optionsBusinessType"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Business Type -->
                <!-- Country -->
                <b-col
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('stepGuideControl.stepVerifyInfo.labelCountry')"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelCountry') }} <span class="text-danger">*</span>
                          </label>
                        </div>
                        <country-select
                          v-model="userData.country"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.labelCountry')"
                          class="input-height country"
                          :country-name="true"
                          :autocomplete="true"
                          :tabindex="3"
                          :country="userData.country"
                          :disabled="!canSelectCountry"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Country -->
                <!-- City/Region -->
                <b-col
                  v-if="!isVNWhiteLabel"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('stepGuideControl.stepVerifyInfo.labelCity')"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelCity') }} <span class="text-danger">*</span>
                          </label>
                        </div>
                        <region-select
                          v-model="userData.city"
                          class="input-height region"
                          :country-name="true"
                          :region-name="true"
                          :autocomplete="true"
                          :tabindex="4"
                          :country="userData.country"
                          :region="userData.city"
                          :disabled="canSelectRegion"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.selectCountry')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- City/Region -->
              </b-row>

              <b-row v-if="!isVNWhiteLabel">
                <!-- Street Address -->
                <b-col
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label class="form-label">
                        {{ $t('stepGuideControl.stepVerifyInfo.labelStreetAddress') }} <span class="text-danger">*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="street-address"
                      rules="required"
                      :name="$t('stepGuideControl.stepVerifyInfo.labelStreetAddress')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.streetAddress"
                          name="street address"
                          class="input-height"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderStreetAddress')"
                          :tabindex="5"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Street Address -->
                <!-- Postal Code -->
                <b-col
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label class="form-label">
                        {{ $t('stepGuideControl.stepVerifyInfo.labelPostalCode') }} <span class="text-danger">*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|postalCodeLength"
                      :name="$t('stepGuideControl.stepVerifyInfo.labelPostalCode')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.postalCode"
                          name="postal-code"
                          class="input-height"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderPostalCode')"
                          :tabindex="6"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Postal Code -->
                <!-- What platform do you use for your store? -->
                <b-col
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('stepGuideControl.stepVerifyInfo.labelWhatPlatform')"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelWhatPlatform') }}
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                        <v-select
                          v-model="userData.storingPlatform"
                          class="currency input-height"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderWhatPlatform')"
                          :tabindex="9"
                          :clearable="false"
                          :options="optionsStoringPlatform"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- What platform do you use for your store? -->
                <!-- What is your current monthly -->
                <b-col
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('stepGuideControl.stepVerifyInfo.labelWhatIsYourMonthly')"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelWhatIsYourMonthly') }}
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                        <v-select
                          v-model="userData.monthlyRevenue"
                          class="currency input-height"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderWhatIsYourMonthly')"
                          :tabindex="10"
                          :clearable="false"
                          :options="optionsMonthlyRevenue"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- What is your current monthly -->
                <!-- Have you run TikTok Ads before ? -->
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="isRunTikTok"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <div>
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('stepGuideControl.stepVerifyInfo.labelHaveYouRunTiktok') }}
                          </label>
                        </div>
                        <v-select
                          v-model="userData.hasRunTikTokAds"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderHaveYouRunTiktok')"
                          class="currency input-height"
                          :tabindex="11"
                          :clearable="false"
                          :options="optionsHasRunTikTok"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Have you run TikTok Ads before ? -->
              </b-row>

              <b-row v-else>
                <!-- Industry -->
                <b-col cols="12">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="industry"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry') }} <span class="text-danger">*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="industry"
                      :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry')"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="userData.industry"
                          id="industry"
                          name="industry"
                          class="input-height"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderIndustry')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Industry -->
                <!-- Product Url / Landing page -->
                <b-col cols="12">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="shopCode"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="product-link"
                      rules="url|productLink"
                      :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="productsData.productURL"
                          id="verify-product-link"
                          data-testid="verify-product-link"
                          name="product link"
                          class="input-height input-product-link"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductUrl')"
                          :tabindex="1"
                          disabled
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Product Url / Landing page -->
                <!-- TikTok Shop Code -->
                <b-col cols="12">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="shopCode"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="shopCode"
                      :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.shopCode"
                          id="shop-code"
                          name="shopCode"
                          class="input-height"
                          disabled
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeHolderTikTokShopCode')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- TikTok Shop Code -->
              </b-row>

              <b-card-title
                title-tag="h1"
                class="title-text mt-2"
              >
                {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textPrimaryContact') }}
              </b-card-title>

              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <phone-input
                      :phone-data.sync="phoneData"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="!isVNWhiteLabel">
                <b-col
                  v-if="!isEmediaPlatform"
                  cols="12"
                >
                  <b-form-group v-if="userData.contactInfo">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="connectFacebook"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.labelConnectViaFacebook') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="connectFacebook"
                      :name="$t('stepGuideControl.stepVerifyInfo.labelConnectViaFacebook')"
                    >
                      <b-input-group
                        class="input-group-merge "
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        prepend="https://www.facebook.com/"
                      >
                        <b-form-input
                          v-model.trim="userData.contactInfo.facebook"
                          id="connect-facebook"
                          name="connectFacebook"
                          class="input-height input-facebook"
                          disabled
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group v-if="userData.contactInfo">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="connectWhatsapp"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.labelConnectViaWhatsApp') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="connectWhatsapp"
                      :name="$t('stepGuideControl.stepVerifyInfo.labelConnectViaWhatsApp')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.contactInfo.whatsApp"
                          id="connect-whatsApp"
                          name="connectWhatsapp"
                          class="input-height"
                          type="number"
                          disabled
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaWhatsApp')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!isEmediaPlatform"
                  cols="12"
                >
                  <b-form-group v-if="userData.contactInfo">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="connectTelegram"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.labelConnectViaTelegram') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="connectTelegram"
                      rules="telegramLength|valExcludeUrl"
                      :name="$t('stepGuideControl.stepVerifyInfo.labelConnectViaTelegram')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.contactInfo.telegram"
                          id="connect-telegram"
                          name="connectTelegram"
                          class="input-height"
                          disabled
                          placeholder="@username"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <b-form-group v-if="userData.contactInfo">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="connectZalo"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.placeholderConnectViaZalo') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="connectZalo"
                      :name="$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaZalo')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="userData.contactInfo.zalo"
                          id="connect-zalo"
                          name="connectZalo"
                          class="input-height"
                          disabled
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter your Zalo number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="btn-control">
                <btn-loading
                  variant-convert="btn-submit"
                  pill
                  :loading="loading"
                  @click="submit"
                >
                  {{ $t('common.btnSaveChanges') }}
                </btn-loading>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
    <verify-phone-modal
      ref="modal-verify-phone"
      :params="params"
      @resendCode="submit"
    />
  </div>
</template>
<script>
/* eslint-disable prefer-destructuring */

import {
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BCardTitle,
  // BButton,
  BInputGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, url, fbUrl, postalCodeLength, telegramLength, valExcludeUrl,
} from '@validations'
import {
  OPTIONS_BUSINESS_TYPE,
  OPTIONS_HAS_RUN_TIK_TOK,
  OPTIONS_PLATFORM_STORE,
  OPTIONS_MONTHLY_REVENUE,
  OPTIONS_BUSINESS_MODEL, PRODUCT_STATUS,
} from '@/constants'
import _get from 'lodash/get'
import vSelect from 'vue-select'
import '@/libs/vue-country-region-select'
import { AuthService } from '@/services'
import envMixin from '@/mixins/envMixin'
import PhoneInput from '../../auth/PhoneInput.vue'
import VerifyPhoneModal from '../../auth/Introduce/VerifyPhoneModal.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')
const {
  mapGetters: mapGettersFile,
  mapActions: mapActionFile,
} = createNamespacedHelpers('file')

export default {
  components: {
    VerifyPhoneModal,
    PhoneInput,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCardTitle,
    // BButton,
    BInputGroup,
    vSelect,
    // components
    BtnLoading,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [toastification, envMixin],
  data() {
    return {
      toggleAvatar: false,
      userData: {
        email: '',
        referralTracking: { code: '' },
        fullName: '',
        type: null,
        businessModel: null,
        country: '',
        city: null,
        streetAddress: null,
        postalCode: null,
        storingPlatform: null,
        monthlyRevenue: null,
        hasRunTikTokAds: null,
        industry: '',

        contactInfo: {
          facebook: '',
          whatsApp: '',
          telegram: '',
        },
      },

      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      phoneData: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },

      // const
      optionsBusinessType: OPTIONS_BUSINESS_TYPE,
      optionsBusinessModel: OPTIONS_BUSINESS_MODEL,
      optionsHasRunTikTok: OPTIONS_HAS_RUN_TIK_TOK,
      optionsStoringPlatform: OPTIONS_PLATFORM_STORE,
      optionsMonthlyRevenue: OPTIONS_MONTHLY_REVENUE,

      params: {},

      // validation
      required,
      url,
      fbUrl,
      postalCodeLength,
      telegramLength,
      valExcludeUrl,
    }
  },

  computed: {
    ...mapGetters(['user', 'status', 'loading', 'message']),

    ...mapGettersFile({
      fileUrl: 'fileUrl',
      statusFile: 'status',
      messageFile: 'message',
    }),

    isVNWhiteLabel() {
      return this.isSMBAgencyPlatform || this.isDC3Platform
    },

    canSelectRegion() {
      return !this.userData?.country
    },

    canSelectCountry() {
      const userCountry = this.user?.data?.country
      return !(userCountry)
    },

    refCode() {
      return _get(this.userData, ['referralTracking', 'code'], '')
    },

    queryType() {
      return this.$route?.query?.type
    },
  },

  async mounted() {
    await this.handleData()
    await this.facebookUrl()
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),
    ...mapActionFile(['uploadFile']),

    facebookUrl() {
      const urlFb = _get(this.userData, ['contactInfo', 'facebook'])
      const params = urlFb?.split('https://www.facebook.com/')
      if (params && params.length > 0) {
        this.userData.contactInfo.facebook = params[1]
      }
    },

    async handleData() {
      await this.getAccountInfo()

      this.userData = { ...this.userData, ..._get(this.user, ['data'], {}) }

      const contactInfo = _get(this.user, ['data', 'contactInfo'])
      const productLink = _get(this.user, ['data', 'products', 'productURLs'])

      if (!contactInfo) {
        this.userData.contactInfo = {
          facebook: '',
          whatsApp: '',
          telegram: '',
        }
      }

      this.phoneInputHandler(contactInfo)

      this.productUrlHandler(productLink)

      this.industry = _get(this.user, ['data', 'industry']) || 'N/A'

      const hasRunTikTokAds = _get(this.user, ['data', 'hasRunTikTokAds'])

      this.userData.hasRunTikTokAds = {
        label:
        // eslint-disable-next-line no-nested-ternary
          hasRunTikTokAds === true
            ? 'Yes'
            : hasRunTikTokAds === false
              ? 'No'
              : '',
        value: _get(this.user, ['data', 'hasRunTikTokAds']),
      }
    },

    async getOTP(params) {
      try {
        const paramsToPost = {
          phoneNumber: params.contactInfo.phone,
        }
        await AuthService.verifyPhoneNumber(paramsToPost)
        // const { ttl } = getResponse(data) || 0
        this.params = {
          ttl: 60,
          userData: params,
        }
        this.$refs['modal-verify-phone'].showModal()
      } catch (e) {
        this.toastFailure(e?.data?.message)
      }
    },

    // async submit() {
    //   const success = await this.$refs.formValidation.validate()
    //   if (success) {
    //     const facebook = this.userData?.contactInfo?.facebook
    //     const telegram = this.userData?.contactInfo?.telegram
    //     const whatsApp = this.userData?.contactInfo?.whatsApp
    //     const params = {
    //       ...this.userData,
    //       hasRunTikTokAds: this.userData.hasRunTikTokAds.value,
    //       contactInfo: {
    //         phone: `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}`,
    //         ...(facebook && { facebook }),
    //         ...(telegram && { telegram }),
    //         ...(whatsApp && { whatsApp }),
    //       },
    //     }
    //
    //     if (
    //       _get(this.user, ['data', 'contactInfo', 'phone'])
    //       !== params.contactInfo.phone
    //     ) {
    //       // check if user update new phone number
    //       this.getOTP(params)
    //     } else {
    //       await this.updateAccount(params)
    //       if (this.status) {
    //         this.toastSuccess(this.$t('profile.textUpdateAccountSuccess'))
    //       } else {
    //         this.toastFailure(this.message)
    //       }
    //     }
    //   }
    // },

    async submit() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        const params = {
          ...this.userData,
          ...(this.productsData.productURL
            && this.hasStepGuide && { products: this.productsData }),
          hasRunTikTokAds: this.userData.hasRunTikTokAds.value,
        }

        delete params.contactInfo
        delete params.products

        await this.updateAccount(params)
        if (this.status) {
          this.toastSuccess(this.$t('profile.textUpdateAccountSuccess'))
          if (this.queryType === 'product-link') {
            window.location.href = '/product-link'
          }
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    productUrlHandler(productLinks) {
      if (productLinks) {
        const convertedUrls = Object.keys(productLinks).map(value => ({
          productUrl: value,
          ...productLinks[value],
        }))

        const arrSortDate = convertedUrls.sort(
          (a, b) => new Date(b.requestedAt) - new Date(a.requestedAt),
        )

        const productLinkReviewing = arrSortDate.find(
          item => item.status === PRODUCT_STATUS.REVIEWING,
        )
        this.productsData.productURL = productLinkReviewing?.productUrl || arrSortDate?.[0].productUrl
      }
    },

    phoneInputHandler(contactInfo) {
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
          this.phoneData.countryAbbr = countryAbbr
          this.phoneData.countryCode = countryCode
          this.phoneData.phone = phone
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.input-facebook {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 1px solid #e0e0e5 !important;
  padding-left: 1rem !important;
}
.input-height {
  height: 48px;
}

.title-text {
  margin-bottom: 32px;
}

.form-group {
  margin-bottom: 32px;
}

.update-information {
  .information-content {
    padding: 40px 32px 40px 46px;

    .top-content {
      margin-bottom: 32px;

      .title-content {
        margin-bottom: 12px;
      }

      .subtitle-content {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.currency {
  .vs__dropdown-toggle {
    height: 100%;
    border-radius: var(--input-border-radius-base);
  }
}

.select-country {
  .country,
  .region {
    width: 100%;
    border: 1px solid rgba(22, 33, 62, 0.1);
    border-radius: var(--input-border-radius-base);
    padding: 0.438rem 1rem;

    &:focus-visible {
      outline: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg data-v-5d7634b4="" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline data-v-5d7634b4="" points="6 9 12 15 18 9"></polyline></svg>');
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
  }
}

.product-collapse {
  .collapse-margin .card {
    border: 1px solid #e0e0e5 !important;
    box-shadow: none !important;
    border-radius: 12px;
  }

  .card-header {
    padding: 14px 18px !important;
    border-bottom: 1px solid #e0e0e5 !important;
    border-radius: 0 !important;
  }

  .card-header.collapsed {
    border-bottom: 0 !important;
  }

  .collapse-title {
    font-size: 14px;
  }

  .card-body {
    padding: 32px !important;
  }

  .collapse-icon :after {
    background-size: 20px !important;
  }
}
</style>
